import { IDataItemEl } from "SRC/redux/slices/main/slices/ministries";
import { IDataItem as IDataItemMin } from "SRC/redux/slices/main/slices/ministries";
interface IIndicatorApi {
  id: string;
  desc: string;
  aggregationFunction?: string;
  stringAggregation?: boolean;
  separator?: string;
}

export interface IDataItem {
  sum?: number;
  code: string;
  text?: string;
}

// export interface IDataItemMin {
//   data: {
//     [x: string]: any;
//     sum?: number;
//     code: string;
//     text?: string;
//   };
// }

export const gpIndicators: Record<string, IIndicatorApi> = {
  1547: {
    id: "1547",
    desc: "Уровень достижения",
    aggregationFunction: "sum",
  },
  1548: {
    id: "1548",
    desc: "Уровень достижения, цвет",
    stringAggregation: true,
    separator: ";",
  },
  1562: {
    id: "1562",
    desc: "Период",
  },
  1565: {
    id: "1565",
    desc: "Госпрограмма",
  },
  1571: {
    id: "1571",
    desc: "Показатели",
  },
  1572: {
    id: "1572",
    desc: "СЭ, план",
    aggregationFunction: "sum",
  },
  1573: {
    id: "1573",
    desc: "СЭ, выполнено",
    aggregationFunction: "sum",
  },
  1574: {
    id: "1574",
    desc: "СЭ, невыполнено",
    aggregationFunction: "sum",
  },
  1575: {
    id: "1575",
    desc: "СЭ, план, итого",
    aggregationFunction: "sum",
  },
  1576: {
    id: "1576",
    desc: "СЭ, выполнено, итого",
    aggregationFunction: "sum",
  },
  1577: {
    id: "1577",
    desc: "СЭ, невыполнено, итого",
    aggregationFunction: "sum",
  },
  1578: {
    id: "1578",
    desc: "Показателей, план",
    aggregationFunction: "sum",
  },
  1579: {
    id: "1579",
    desc: "Показателей, выполнено",
    aggregationFunction: "sum",
  },
  1580: {
    id: "1580",
    desc: "Показателей, невыполнено",
    aggregationFunction: "sum",
  },
  1581: {
    id: "1581",
    desc: "Показателей, план, итого",
    aggregationFunction: "sum",
  },
  1582: {
    id: "1582",
    desc: "Показателей, выполнено, итого",
    aggregationFunction: "sum",
  },
  1583: {
    id: "1583",
    desc: "Показателей, невыполнено, итого",
    aggregationFunction: "sum",
  },
  1584: {
    id: "1584",
    desc: "Мероприятий, план",
    aggregationFunction: "sum",
  },
  1585: {
    id: "1585",
    desc: "Мероприятий, выполнено",
    aggregationFunction: "sum",
  },
  1586: {
    id: "1586",
    desc: "Мероприятий, невыполнено",
    aggregationFunction: "sum",
  },
  1587: {
    id: "1587",
    desc: "Мероприятий, план, итого",
    aggregationFunction: "sum",
  },
  1588: {
    id: "1588",
    desc: "Мероприятий, выполнено, итого",
    aggregationFunction: "sum",
  },
  1589: {
    id: "1589",
    desc: "Мероприятий, невыполнено, итого",
    aggregationFunction: "sum",
  },
  1590: {
    id: "1590",
    desc: "Касса, план",
    aggregationFunction: "sum",
  },
  1591: {
    id: "1591",
    desc: "Касса, факт, доля",
    aggregationFunction: "sum",
  },
  1592: {
    id: "1592",
    desc: "Касса, цвет",
    stringAggregation: true,
    separator: ";",
  },
  1593: {
    id: "1593",
    desc: "Касса, план, итого",
    aggregationFunction: "sum",
  },
  1594: {
    id: "1594",
    desc: "Касса, факт, доля, итого",
    aggregationFunction: "sum",
  },
  1595: {
    id: "1595",
    desc: "Касса, цвет, итого",
    stringAggregation: true,
    separator: ";",
  },
  1596: {
    id: "1596",
    desc: "ОКС, план",
    aggregationFunction: "sum",
  },
  1597: {
    id: "1597",
    desc: "ОКС, выполнено, доля",
    aggregationFunction: "sum",
  },
  1598: {
    id: "1598",
    desc: "ОКС, план, итого",
    aggregationFunction: "sum",
  },
  1599: {
    id: "1599",
    desc: "ОКС, выполнено, доля, итого",
    aggregationFunction: "sum",
  },
  1600: {
    id: "1600",
    desc: "НПА, план",
    aggregationFunction: "sum",
  },
  1601: {
    id: "1601",
    desc: "НПА, выполнено",
    aggregationFunction: "sum",
  },
  1602: {
    id: "1602",
    desc: "НПА, невыполнено",
    aggregationFunction: "sum",
  },
  1603: {
    id: "1603",
    desc: "НПА, план, итого",
    aggregationFunction: "sum",
  },
  1604: {
    id: "1604",
    desc: "НПА, выполнено, итого",
    aggregationFunction: "sum",
  },
  1605: {
    id: "1605",
    desc: "НПА, невыполнено, итого",
    aggregationFunction: "sum",
  },
  1652: {
    id: "1652",
    desc: "ОКС, цвет, итого",
    stringAggregation: true,
    separator: ";",
  },
  1653: {
    id: "1653",
    desc: "ОКС, цвет",
    stringAggregation: true,
    separator: ";",
  },
  1678: {
    id: "1678",
    desc: "Уровень достижения ФП",
    aggregationFunction: "sum",
  },
  1679: {
    id: "1679",
    desc: "Мероприятия, факт",
    aggregationFunction: "sum",
  },
  1680: {
    id: "1680",
    desc: "Мероприятия, под риском",
    aggregationFunction: "sum",
  },
  1681: {
    id: "1681",
    desc: "Мероприятия, УД",
    aggregationFunction: "sum",
  },
  1730: {
    id: "1730",
    desc: "Уровень достижения, общий",
    aggregationFunction: "sum",
  },
  1731: {
    id: "1731",
    desc: "Уровень достижения, общий, цвет",
    stringAggregation: true,
    separator: ";",
  },
  1753: {
    id: "1753",
    desc: "Уровень достижения",
    aggregationFunction: "sum",
  },
  1754: {
    id: "1754",
    desc: "Касса, факт",
    aggregationFunction: "sum",
  },
  1755: {
    id: "1755",
    desc: "Цели, план",
    aggregationFunction: "sum",
  },
  1756: {
    id: "1756",
    desc: "Цели, выполнено",
    aggregationFunction: "sum",
  },
  1757: {
    id: "1757",
    desc: "Цели, невыполнено",
    aggregationFunction: "sum",
  },
  1769: {
    id: "1769",
    desc: "Список целей",
    stringAggregation: true,
    separator: ";",
  },
  1796: {
    id: "1796",
    desc: "Уровень достижения 2",
    aggregationFunction: "sum",
  },
  1843: {
    id: "1843",
    desc: "ГП под риском",
    aggregationFunction: "sum",
  },
  1844: {
    id: "1844",
    desc: "Секретные ГП",
    aggregationFunction: "sum",
  },
};

export const getDataIdGP = (val: string | number): string | undefined =>
  gpIndicators[val]?.id;

export const getDataSumByCodeGP = (
  item: { data: IDataItem[] } = { data: [] },
  val: string | number
) => item?.data?.find((el) => el.code === getDataIdGP(val))?.sum;

export const getDataTextByCodeGP = (
  item: { data: IDataItem[] } = { data: [] },
  val: string | number
): string | undefined =>
  item?.data?.find((el) => el.code === getDataIdGP(val))?.text;

export const getGpIndicators = (...args: (number | string)[]) =>
  args.map((id) => gpIndicators[id]).filter(Boolean);

export const vpIndicators: Record<string, IIndicatorApi> = {
  1683: {
    id: "1683",
    desc: "Период",
  },
  1686: {
    id: "1686",
    desc: "Вице-премьер",
  },
  1692: {
    id: "1692",
    desc: "Показатели",
  },
  1693: {
    id: "1693",
    desc: "СЭ, план",
    aggregationFunction: "sum",
  },
  1694: {
    id: "1694",
    desc: "СЭ, выполнено",
    aggregationFunction: "sum",
  },
  1695: {
    id: "1695",
    desc: "СЭ, невыполнено",
    aggregationFunction: "sum",
  },
  1696: {
    id: "1696",
    desc: "СЭ, план, итого",
    aggregationFunction: "sum",
  },
  1697: {
    id: "1697",
    desc: "СЭ, выполнено, итого",
    aggregationFunction: "sum",
  },
  1698: {
    id: "1698",
    desc: "СЭ, невыполнено, итого",
    aggregationFunction: "sum",
  },
  1699: {
    id: "1699",
    desc: "Показателей, план",
    aggregationFunction: "sum",
  },
  1700: {
    id: "1700",
    desc: "Показателей, выполнено",
    aggregationFunction: "sum",
  },
  1701: {
    id: "1701",
    desc: "Показателей, невыполнено",
    aggregationFunction: "sum",
  },
  1702: {
    id: "1702",
    desc: "Показателей, план, итого",
    aggregationFunction: "sum",
  },
  1703: {
    id: "1703",
    desc: "Показателей, выполнено, итого",
    aggregationFunction: "sum",
  },
  1704: {
    id: "1704",
    desc: "Показателей, невыполнено, итого",
    aggregationFunction: "sum",
  },
  1705: {
    id: "1705",
    desc: "Мероприятий, план",
    aggregationFunction: "sum",
  },
  1706: {
    id: "1706",
    desc: "Мероприятий, выполнено",
    aggregationFunction: "sum",
  },
  1707: {
    id: "1707",
    desc: "Мероприятий, невыполнено",
    aggregationFunction: "sum",
  },
  1708: {
    id: "1708",
    desc: "Мероприятий, план, итого",
    aggregationFunction: "sum",
  },
  1709: {
    id: "1709",
    desc: "Мероприятий, выполнено, итого",
    aggregationFunction: "sum",
  },
  1710: {
    id: "1710",
    desc: "Мероприятий, невыполнено, итого",
    aggregationFunction: "sum",
  },
  1711: {
    id: "1711",
    desc: "Касса, план",
    aggregationFunction: "sum",
  },
  1712: {
    id: "1712",
    desc: "Касса, факт, доля",
    aggregationFunction: "sum",
  },
  1713: {
    id: "1713",
    desc: "Касса, цвет",
    stringAggregation: true,
    separator: ";",
  },
  1714: {
    id: "1714",
    desc: "Касса, план, итого",
    aggregationFunction: "sum",
  },
  1715: {
    id: "1715",
    desc: "Касса, факт, доля, итого",
    aggregationFunction: "sum",
  },
  1716: {
    id: "1716",
    desc: "Касса, цвет, итого",
    stringAggregation: true,
    separator: ";",
  },
  1717: {
    id: "1717",
    desc: "ОКС, план",
    aggregationFunction: "sum",
  },
  1718: {
    id: "1718",
    desc: "ОКС, выполнено, доля",
    aggregationFunction: "sum",
  },
  1719: {
    id: "1719",
    desc: "ОКС, план, итого",
    aggregationFunction: "sum",
  },
  1720: {
    id: "1720",
    desc: "ОКС, выполнено, доля, итого",
    aggregationFunction: "sum",
  },
  1721: {
    id: "1721",
    desc: "НПА, план",
    aggregationFunction: "sum",
  },
  1722: {
    id: "1722",
    desc: "НПА, выполнено",
    aggregationFunction: "sum",
  },
  1723: {
    id: "1723",
    desc: "НПА, невыполнено",
    aggregationFunction: "sum",
  },
  1724: {
    id: "1724",
    desc: "НПА, план, итого",
    aggregationFunction: "sum",
  },
  1725: {
    id: "1725",
    desc: "НПА, выполнено, итого",
    aggregationFunction: "sum",
  },
  1726: {
    id: "1726",
    desc: "НПА, невыполнено, итого",
    aggregationFunction: "sum",
  },
  1727: {
    id: "1727",
    desc: "ОКС, цвет, итого",
    stringAggregation: true,
    separator: ";",
  },
  1728: {
    id: "1728",
    desc: "ОКС, цвет",
    stringAggregation: true,
    separator: ";",
  },
  1742: {
    id: "1742",
    desc: "Количество ГП",
    aggregationFunction: "sum",
  },
  1743: {
    id: "1743",
    desc: "УД",
    aggregationFunction: "sum",
  },
  1744: {
    id: "1744",
    desc: "УД, цвет",
    stringAggregation: true,
    separator: ";",
  },
  1745: {
    id: "1745",
    desc: "УД, общий",
    aggregationFunction: "sum",
  },
  1746: {
    id: "1746",
    desc: "УД, общий, цвет",
    stringAggregation: true,
    separator: ";",
  },
  1747: {
    id: "1747",
    desc: "Мероприятия, план",
    aggregationFunction: "sum",
  },
  1748: {
    id: "1748",
    desc: "Мероприятия, факт",
    aggregationFunction: "sum",
  },
  1749: {
    id: "1749",
    desc: "Мероприятия, риски",
    aggregationFunction: "sum",
  },
  1758: {
    id: "1758",
    desc: "Количество ГП",
    aggregationFunction: "sum",
  },
  1759: {
    id: "1759",
    desc: "Уровень достижения",
    aggregationFunction: "sum",
  },
  1760: {
    id: "1760",
    desc: "Касса, факт",
    aggregationFunction: "sum",
  },
  1761: {
    id: "1761",
    desc: "Цели, план",
    aggregationFunction: "sum",
  },
  1762: {
    id: "1762",
    desc: "Цели, выполнено",
    aggregationFunction: "sum",
  },
  1763: {
    id: "1763",
    desc: "Цели, невыполнено",
    aggregationFunction: "sum",
  },
  1764: {
    id: "1764",
    desc: "Удовлетворённость населения",
    aggregationFunction: "sum",
  },
  1765: {
    id: "1765",
    desc: "Список госпрограмм",
    stringAggregation: true,
    separator: ";",
  },
  1766: {
    id: "1766",
    desc: "Список целей",
    stringAggregation: true,
    separator: ";",
  },
  1767: {
    id: "1767",
    desc: "Список госпрограмм",
    stringAggregation: true,
    separator: ";",
  },
  1768: {
    id: "1768",
    desc: "Список целей",
    stringAggregation: true,
    separator: ";",
  },
  1797: {
    id: "1797",
    desc: "Уровень достижения 2",
    aggregationFunction: "sum",
  },
};

export const getDataIdVP = (val: string | number): string | undefined =>
  vpIndicators[val]?.id;

export const getDataSumByCodeVP = (
  item: { data: IDataItem[] } = { data: [] },
  val: string | number
) => item?.data?.find((el) => el.code === getDataIdVP(val))?.sum;

export const getVpIndicators = (...args: (number | string)[]) =>
  args.map((id) => vpIndicators[id]).filter(Boolean);

const eventIndicators: Record<string, IIndicatorApi> = {
  1344: {
    id: "1344",
    desc: "Период",
  },
  1348: {
    id: "1348",
    desc: "Мероприятие",
  },
  1351: {
    id: "1351",
    desc: "Показатели",
  },
  1352: {
    id: "1352",
    desc: "Значение, план",
    aggregationFunction: "sum",
  },
  1358: {
    id: "1358",
    desc: "Значение, факт",
    aggregationFunction: "sum",
  },
  1359: {
    id: "1359",
    desc: "Значение, процент достижения",
    aggregationFunction: "sum",
  },
  1360: {
    id: "1360",
    desc: "Финансирование, план",
    aggregationFunction: "sum",
  },
  1361: {
    id: "1361",
    desc: "Финансирование, факт",
    aggregationFunction: "sum",
  },
  1362: {
    id: "1362",
    desc: "Финансирование, процент достижения",
    aggregationFunction: "sum",
  },
  1363: {
    id: "1363",
    desc: "Реализация в субъектах, план",
    aggregationFunction: "sum",
  },
  1364: {
    id: "1364",
    desc: "Реализация в субъектах, факт",
    aggregationFunction: "sum",
  },
  1365: {
    id: "1365",
    desc: "Реализация в субъектах, процент достижения",
    aggregationFunction: "sum",
  },
  1366: {
    id: "1366",
    desc: "Срок реализации, плановая дата",
    stringAggregation: true,
    separator: ";",
  },
  1367: {
    id: "1367",
    desc: "Срок реализации, фактическая дата",
    stringAggregation: true,
    separator: ";",
  },
  1468: {
    id: "1468",
    desc: "В реализации",
  },
  1469: {
    id: "1469",
    desc: "Новое",
  },
  1470: {
    id: "1470",
    desc: "Выполнено",
  },
  1471: {
    id: "1471",
    desc: "Под риском",
  },
  1472: {
    id: "1472",
    desc: "Не выполнено",
  },
  1473: {
    id: "1473",
    desc: "Всего мероприятий",
    aggregationFunction: "sum",
  },
  1474: {
    id: "1474",
    desc: "Государственные программы",
    stringAggregation: true,
    separator: ";",
  },
  1475: {
    id: "1475",
    desc: "Национальные проекты",
    stringAggregation: true,
    separator: ";",
  },
  1476: {
    id: "1476",
    desc: "Иные мероприятия",
    stringAggregation: true,
    separator: ";",
  },
  1477: {
    id: "1477",
    desc: "Влияние",
  },
  1478: {
    id: "1478",
    desc: "Вектор влияния",
  },
  1479: {
    id: "1479",
    desc: "Признак_основные",
  },
  1480: {
    id: "1480",
    desc: "Признак_не основные",
  },
  1481: {
    id: "1481",
    desc: "Признак_ОКС онлайн",
  },
  1482: {
    id: "1482",
    desc: "Признак_Реализация в субъектах",
  },
  1483: {
    id: "1483",
    desc: "Признак_СЗП",
  },
  1484: {
    id: "1484",
    desc: "Признак_ФХД",
  },
  1485: {
    id: "1485",
    desc: "Признак_КТ",
  },
  1486: {
    id: "1486",
    desc: "Признак_ОКС",
  },
  1487: {
    id: "1487",
    desc: "Признак_ЛЮГ",
  },
  1488: {
    id: "1488",
    desc: "Признак_СМНП",
  },
  1489: {
    id: "1489",
    desc: "Риск_Значение Результата",
  },
  1490: {
    id: "1490",
    desc: "Риск_Срок реализации",
  },
  1491: {
    id: "1491",
    desc: "Риск_Финансирование",
  },
  1492: {
    id: "1492",
    desc: "Риск_Финансирование Ведомство",
  },
  1493: {
    id: "1493",
    desc: "Риск_Финансирование Негативный",
  },
  1494: {
    id: "1494",
    desc: "Риск_Реализация в субъектах",
  },
  1495: {
    id: "1495",
    desc: "Значение результата. Факт. Цвет",
  },
  1496: {
    id: "1496",
    desc: "Финансирование. Факт. Цвет",
  },
  1497: {
    id: "1497",
    desc: "Срок реализации. Конец. Цвет",
  },
  1498: {
    id: "1498",
    desc: "Реализация в субъектах. Факт. Цвет",
  },
  1499: {
    id: "1499",
    desc: "Риск_Финансирование Сценарий",
  },
  1500: {
    id: "1500",
    desc: "Кассовое исполнение%",
  },
  1501: {
    id: "1501",
    desc: "Кассовое исполнение_цвет",
  },
  1502: {
    id: "1502",
    desc: "Признак_ВПЦТ",
  },
  1503: {
    id: "1503",
    desc: "ВПЦТ_Ссылка",
  },
  1666: {
    id: "1666",
    desc: "Статус",
  },
  1750: {
    id: "1750",
    desc: "Значение процент достижения факт к плану",
    aggregationFunction: "sum",
  },
  1823: {
    id: "1823",
    desc: "Корректировки увеличилось значение",
    aggregationFunction: "sum",
  },
  1824: {
    id: "1824",
    desc: "Корректировки увеличилось значение, срок",
    aggregationFunction: "sum",
  },
  1825: {
    id: "1825",
    desc: "Корректировки ухудшилось значение",
    aggregationFunction: "sum",
  },
  1826: {
    id: "1826",
    desc: "Корректировки ухудшилось значение, срок",
    aggregationFunction: "sum",
  },
  1827: {
    id: "1827",
    desc: "Значение, прогноз",
    aggregationFunction: "sum",
  },
};

export const getDataIdEvent = (val: string | number): string | undefined =>
  eventIndicators[val]?.id;

export const getDataSumByCodeEvent = (
  item: { data: IDataItem[] } = { data: [] },
  val: string | number
) => item?.data?.find((el) => el.code === getDataIdEvent(val))?.sum;

export const getDataTextByCodeEvent = (
  item: { data: IDataItem[] } = { data: [] },
  val: string | number
): string | undefined =>
  item?.data?.find((el) => el.code === getDataIdEvent(val))?.text;

export const getEventIndicators = (...args: (number | string)[]) =>
  args.map((id) => eventIndicators[id]).filter(Boolean);

const ministriesIndicators: Record<string, IIndicatorApi> = {
  1770: {
    id: "1770",
    desc: "Министерства",
  },
  1774: {
    id: "1774",
    desc: "Период",
  },

  1782: {
    id: "1782",
    desc: "Госпрограммы",
    aggregationFunction: "sum",
  },
  1783: {
    id: "1783",
    desc: "Показатели. План",
    aggregationFunction: "sum",
  },
  1784: {
    id: "1784",
    desc: "Показатели. Выполнено",
    aggregationFunction: "sum",
  },
  1785: {
    id: "1785",
    desc: "Мероприятия. План",
    aggregationFunction: "sum",
  },
  1786: {
    id: "1786",
    desc: "Мероприятия. Факт",
    aggregationFunction: "sum",
  },
  1787: {
    id: "1787",
    desc: "Мероприятия. Под риском",
    aggregationFunction: "sum",
  },
  1788: {
    id: "1788",
    desc: "Уровень достижения",
    aggregationFunction: "sum",
  },
};

export const getDataIdMinistries = (val: string | number): string | undefined =>
  ministriesIndicators[val]?.id;

export const getMinistriesIndicators = (...args: (number | string)[]) =>
  args.map((id) => ministriesIndicators[id]).filter(Boolean);

export const getDataSumByCodeMin = (
  item: { data: IDataItemMin[] } = { data: [] },
  val: string | number
) =>
  item.data[0].data.find(
    (el: IDataItemEl) => el.code === getDataIdMinistries(val)
  )?.sum;
