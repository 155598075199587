import { Theme } from "@mui/material";

export const css = {
  tabs: (theme: Theme) => ({
    "& .MuiTabs-root": {
      width: "100%",
    },
    "& .MuiTab-root": {
      fontSize: "1.25rem",
      padding: "1rem 0rem",
      [theme.breakpoints.down(1300)]: {
        fontSize: "0.7rem",
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: "1rem",
      },
    },
    "& .MuiTabs-flexContainer": {
      justifyContent: "space-between",
      padding: "0 2.5rem",
    },
  }),
  tabPanel: {
    padding: "0 !important",
  },
  tabPanelCharacter: {
    maxHeight: "34rem",
    overflowY: "scroll",
  },
};
