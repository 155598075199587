import { olapRequestData2, olapRequestData3 } from "CORE/api/core";
import { MODEL_UUID, SECTION_NAMES } from "CORE/scheme/olap";
import { getEventIndicators, getGpIndicators } from "SRC/constants/dataCodes";
import { IParams } from "SRC/redux/slices/gosprogram/slices/programEvents";
import { IEventParams } from "SRC/redux/slices/gosprogram/slices/programFilters";

export const getProgramGoals = (id: string | number) => {
  return olapRequestData2({
    dimensions: [],
    indicators: {},
    section_name: SECTION_NAMES.PROGRAMGOALS,
    modelUuid: MODEL_UUID,
    filters: {
      period_code: "202200",
      gp_code: id,
    },
  });
};

export const getStructureElements = (id: string | number) => {
  return olapRequestData2({
    dimensions: [],
    indicators: {},
    section_name: SECTION_NAMES.STRUCTURE,
    modelUuid: MODEL_UUID,
    filters: {
      period_code: "202200",
      gp_code: id,
    },
  });
};

export const getProgramEvents = (params: IParams) => {
  return olapRequestData2({
    dimensions: [],
    indicators: {},
    section_name: SECTION_NAMES.PROGRAMEVENTS,
    modelUuid: MODEL_UUID,
    filters: {
      period_code: "202200",
      gp_code: params.id,
      fp_code: params.fp_code,
      dp_code: params.dp_code,
      cpm_code: params.cpm_code,
    },
  });
};

export const getStatistics = (id: string | number) => {
  return olapRequestData2({
    dimensions: [],
    indicators: {},
    section_name: SECTION_NAMES.STATS,
    modelUuid: MODEL_UUID,
    filters: {
      period_code: "202200",
      gp_code: id,
    },
  });
};

export const getControlPoints = (params: IEventParams) => {
  return olapRequestData3([
    {
      requestId: "4bdb46ae-2ba5-48ad-9156-3cec83a0a86f",
      type: "data2",
      params: {
        modelUuid: "9470732b-770c-4da1-b53e-da810caeb9fb",
        dashboardUuid: "",
        includeGaps: false,
        dimensions: [
          {
            id: "1369",
            includeItems: true,
            includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
          },
          {
            id: "1374",
            includeItems: true,
            includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
          },
        ],
        indicators: {
          id: "1379",
          items: [
            {
              id: "1380",
              stringAggregation: true,
              separator: ";",
            },
            {
              id: "1381",
              stringAggregation: true,
              separator: ";",
            },
            {
              id: "1504",
              stringAggregation: true,
              separator: ";",
            },
            {
              id: "1505",
              stringAggregation: true,
              separator: ";",
            },
            {
              id: "1729",
              stringAggregation: true,
              separator: ";",
            },
            {
              id: "1830",
              aggregationFunction: "sum",
            },
            {
              id: "1831",
              stringAggregation: true,
              separator: ";",
            },
          ],
        },
        dataRequestCaching: true,
        dataSort: [
          {
            dim_id: "1369",
            sortField: "name",
            sortOrder: "asc",
          },
          {
            dim_id: "1374",
            sortField: "name",
            sortOrder: "asc",
          },
        ],
        dataFilter: {
          type: "AND",
          version: 1,
          operands: [
            {
              type: "EQ",
              version: 1,
              operands: [
                {
                  type: "DIM",
                  version: 1,
                  id: "1369",
                },
                {
                  type: "CONST",
                  version: 1,
                  values: [params.period.slice(0, 4)],
                },
              ],
            },
            {
              type: "EQ",
              version: 1,
              operands: [
                {
                  type: "DIM",
                  version: 2,
                  id: "1374",
                  attributeCode: "R_ID",
                },
                {
                  type: "TEXT",
                  version: 2,
                  values: [params.id],
                },
              ],
            },
          ],
        },
      },
    },
  ]);
};

export const getCashExecution = (params: IEventParams) => {
  return olapRequestData3([
    {
      requestId: "d9ad9f1b-2287-49e9-9b55-abda2f0bc2a8",
      type: "data2",
      params: {
        modelUuid: "45c0a0bb-d4ed-4541-ba6f-088aef4e5511",
        dashboardUuid: "",
        includeGaps: false,
        dimensions: [
          {
            id: "1427",
            includeItems: true,
            includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
          },
          {
            id: "1432",
            includeItems: true,
            includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
          },
          {
            id: "1436",
            includeItems: true,
            includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
          },
        ],
        indicators: {
          id: "1441",
          items: [
            {
              id: "1442",
              aggregationFunction: "sum",
            },
            {
              id: "1443",
              aggregationFunction: "sum",
            },
            {
              id: "1677",
              aggregationFunction: "sum",
            },
          ],
        },
        dataRequestCaching: true,
        dataSort: [
          {
            dim_id: "1427",
            sortField: "name",
            sortOrder: "asc",
          },
          {
            dim_id: "1432",
            sortField: "name",
            sortOrder: "asc",
          },
          {
            dim_id: "1436",
            sortField: "name",
            sortOrder: "asc",
          },
        ],
        dataFilter: {
          type: "AND",
          version: 1,
          operands: [
            {
              type: "STARTER",
              version: 1,
              dim_id: "1436",
            },
            {
              type: "AND",
              version: 1,
              operands: [
                {
                  type: "EQ",
                  version: 1,
                  operands: [
                    {
                      type: "DIM",
                      version: 1,
                      id: "1427",
                    },
                    {
                      type: "CONST",
                      version: 1,
                      values: [params.period.slice(0, 4)],
                    },
                  ],
                },
                {
                  type: "EQ",
                  version: 1,
                  operands: [
                    {
                      type: "DIM",
                      version: 2,
                      id: "1432",
                      attributeCode: "R_ID",
                    },
                    {
                      type: "TEXT",
                      version: 2,
                      values: [params.id],
                    },
                  ],
                },
              ],
            },
          ],
        },
      },
    },
  ]);
};

export const getSubjects = (params: IEventParams) => {
  return olapRequestData3([
    {
      requestId: "ae874083-032a-4010-a5c7-81c19792d3e6",
      type: "data2",
      params: {
        modelUuid: "46a04ab2-5e97-49b8-b42d-8844ece88440",
        dashboardUuid: "",
        includeGaps: false,
        dimensions: [
          {
            id: "1405",
            includeItems: true,
            includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
          },
          {
            id: "1410",
            includeItems: true,
            includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
          },
          {
            id: "1414",
            includeItems: true,
            includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
          },
        ],
        indicators: {
          id: "1420",
          items: [
            {
              id: "1421",
              aggregationFunction: "sum",
            },
            {
              id: "1422",
              aggregationFunction: "sum",
            },
            {
              id: "1423",
              stringAggregation: true,
              separator: ";",
            },
            {
              id: "1424",
              stringAggregation: true,
              separator: ";",
            },
          ],
        },
        dataRequestCaching: true,
        dataSort: [
          {
            dim_id: "1405",
            sortField: "name",
            sortOrder: "asc",
          },
          {
            dim_id: "1410",
            sortField: "name",
            sortOrder: "asc",
          },
          {
            dim_id: "1414",
            sortField: "name",
            sortOrder: "asc",
          },
        ],
        dataFilter: {
          type: "AND",
          version: 1,
          operands: [
            {
              type: "STARTER",
              version: 1,
              dim_id: "1414",
            },
            {
              type: "AND",
              version: 1,
              operands: [
                {
                  type: "EQ",
                  version: 1,
                  operands: [
                    {
                      type: "DIM",
                      version: 1,
                      id: "1405",
                    },
                    {
                      type: "CONST",
                      version: 1,
                      values: [params.period.slice(0, 4)],
                    },
                  ],
                },
                {
                  type: "EQ",
                  version: 1,
                  operands: [
                    {
                      type: "DIM",
                      version: 2,
                      id: "1410",
                      attributeCode: "R_ID",
                    },
                    {
                      type: "TEXT",
                      version: 2,
                      values: [params.id],
                    },
                  ],
                },
              ],
            },
          ],
        },
      },
    },
  ]);
};

export const getListOfObjects = (params: IEventParams) => {
  return olapRequestData3([
    {
      requestId: "a44d854c-f0b5-4ca5-88f7-6af47f4dcbf1",
      type: "data2",
      params: {
        modelUuid: "6f061c05-5d00-42e9-a92c-d1b59b2bb718",
        dashboardUuid: "",
        includeGaps: false,
        dimensions: [
          {
            id: "1382",
            includeItems: true,
            includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
          },
          {
            id: "1387",
            includeItems: true,
            includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
          },
          {
            id: "1662",
            includeItems: true,
            includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
          },
        ],
        indicators: {
          id: "1402",
          items: [
            {
              id: "1403",
              aggregationFunction: "sum",
            },
            {
              id: "1404",
              aggregationFunction: "sum",
            },
            {
              id: "1455",
              aggregationFunction: "sum",
            },
            {
              id: "1456",
              aggregationFunction: "sum",
            },
            {
              id: "1462",
              stringAggregation: true,
              separator: ";",
            },
            {
              id: "1463",
              stringAggregation: true,
              separator: ";",
            },
          ],
        },
        dataRequestCaching: true,
        dataSort: [
          {
            dim_id: "1382",
            sortField: "name",
            sortOrder: "asc",
          },
          {
            dim_id: "1387",
            sortField: "name",
            sortOrder: "asc",
          },
          {
            dim_id: "1662",
            sortField: "name",
            sortOrder: "asc",
          },
        ],
        dataFilter: {
          type: "AND",
          version: 1,
          operands: [
            {
              type: "STARTER",
              version: 1,
              dim_id: "1387",
            },
            {
              type: "AND",
              version: 1,
              operands: [
                {
                  type: "EQ",
                  version: 1,
                  operands: [
                    {
                      type: "DIM",
                      version: 1,
                      id: "1382",
                    },
                    {
                      type: "CONST",
                      version: 1,
                      values: [
                        params.period.indexOf("00", 4) === 4
                          ? params.period.slice(0, 4)
                          : params.period,
                      ],
                    },
                  ],
                },
                {
                  type: "EQ",
                  version: 1,
                  operands: [
                    {
                      type: "DIM",
                      version: 2,
                      id: "1662",
                      attributeCode: "R_ID",
                    },
                    {
                      type: "TEXT",
                      version: 2,
                      values: [params.id],
                    },
                  ],
                },
              ],
            },
          ],
        },
      },
    },
  ]);
};

export const getResultCharacteristic = (params: IEventParams) => {
  return olapRequestData3([
    {
      requestId: "4dcc7514-b4c3-4015-87c0-bc6bdd0c1fd3",
      type: "dimension_data",
      params: {
        modelUuid: "f0c00f6b-d2fa-4a9a-9f57-258a0e88806d",
        dimensionId: "1344",
        includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
        dataFilter: {
          type: "EQ",
          version: 1,
          operands: [
            {
              type: "DIM",
              version: 1,
              id: "1344",
            },
            {
              type: "CONST",
              version: 1,
              values: [params.period],
            },
          ],
        },
      },
    },
    {
      requestId: "a80a0421-1fb0-49e4-90e8-a27c9346eeb5",
      type: "dimension_data",
      params: {
        modelUuid: "f0c00f6b-d2fa-4a9a-9f57-258a0e88806d",
        dimensionId: "1348",
        includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
        dataFilter: {
          type: "EQ",
          version: 1,
          operands: [
            {
              type: "DIM",
              version: 1,
              id: "1348",
            },
            {
              type: "CONST",
              version: 1,
              values: ["10103"],
            },
          ],
        },
      },
    },
  ]);
};

export const getModalSideBar = (params: IEventParams) => {
  return olapRequestData3([
    {
      requestId: "fd5a914c-f303-4d26-ad05-8751a0ee6310",
      type: "data2",
      params: {
        modelUuid: "f0c00f6b-d2fa-4a9a-9f57-258a0e88806d",
        dashboardUuid: "",
        includeGaps: false,
        dimensions: [
          {
            id: "1344",
            includeItems: true,
            includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
          },
          {
            id: "1348",
            includeItems: true,
            includeAttributesByCodes: [
              "NAME",
              "SHORT_NAME",
              "SORT_ORDER",
              "Ед. измерения результата",
              "Ед. измерения бюджета",
            ],
          },
        ],
        indicators: {
          id: "1351",
          items: getEventIndicators(
            1352,
            1358,
            1359,
            1360,
            1361,
            1362,
            1363,
            1364,
            1365,
            1366,
            1367,
            1750
          ),
        },
        dataRequestCaching: true,
        dataSort: [
          {
            dim_id: "1344",
            sortField: "name",
            sortOrder: "asc",
          },
          {
            dim_id: "1348",
            sortField: "name",
            sortOrder: "asc",
          },
        ],
        dataFilter: {
          type: "AND",
          version: 1,
          operands: [
            {
              type: "EQ",
              version: 1,
              operands: [
                {
                  type: "DIM",
                  version: 1,
                  id: "1344",
                },
                {
                  type: "CONST",
                  version: 1,
                  values: [params.period.slice(0, 4)],
                },
              ],
            },
            {
              type: "EQ",
              version: 1,
              operands: [
                {
                  type: "DIM",
                  version: 2,
                  id: "1348",
                  attributeCode: "R_ID",
                },
                {
                  type: "TEXT",
                  version: 2,
                  values: [params.id],
                },
              ],
            },
          ],
        },
      },
    },
  ]);
};

export const getModalNewSidebar = (params: IEventParams) => {
  return olapRequestData3([
    {
      requestId: "20983682-8107-4261-9f13-c7a107412af4",
      type: "data2",
      params: {
        modelUuid: "f0c00f6b-d2fa-4a9a-9f57-258a0e88806d",
        dashboardUuid: "",
        includeGaps: false,
        dimensions: [
          {
            id: "1344",
            includeItems: true,
            includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
          },
          {
            id: "1348",
            includeItems: true,
            includeAttributesByCodes: [
              "NAME",
              "SHORT_NAME",
              "SORT_ORDER",
              "Ед. измерения результата",
              "Ед. измерения бюджета",
              "SortOrder",
              "Наименование проекта",
            ],
          },
        ],
        indicators: {
          id: "1351",
          items: getEventIndicators(
            1352,
            1358,
            1359,
            1360,
            1361,
            1362,
            1363,
            1364,
            1365,
            1366,
            1367,
            1750,
            1823,
            1824,
            1825,
            1826,
            1827
          ),
        },
        dataRequestCaching: true,
        dataSort: [
          {
            dim_id: "1344",
            sortField: "name",
            sortOrder: "asc",
          },
          {
            dim_id: "1348",
            sortField: "name",
            sortOrder: "asc",
          },
        ],
        dataFilter: {
          type: "AND",
          version: 1,
          operands: [
            {
              type: "EQ",
              version: 1,
              operands: [
                {
                  type: "DIM",
                  version: 1,
                  id: "1344",
                },
                {
                  type: "CONST",
                  version: 1,
                  values: [params.period.slice(0, 4)],
                },
              ],
            },
            {
              type: "EQ",
              version: 1,
              operands: [
                {
                  type: "DIM",
                  version: 2,
                  id: "1348",
                  attributeCode: "R_ID",
                },
                {
                  type: "TEXT",
                  version: 2,
                  values: [params.id],
                },
              ],
            },
          ],
        },
      },
    },
  ]);
};

export const getObjectPhotos = (params: IEventParams) => {
  return olapRequestData3([
    {
      requestId: "0cce9e0d-f40b-44d5-a627-2fe7d20deac9",
      type: "data2",
      params: {
        modelUuid: "6f061c05-5d00-42e9-a92c-d1b59b2bb718",
        dashboardUuid: "",
        includeGaps: false,
        dimensions: [
          {
            id: "1382",
            includeItems: true,
            includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
          },
          {
            id: "1387",
            includeItems: true,
            includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
          },
        ],
        indicators: {
          id: "1402",
          items: [
            {
              id: "1457",
              stringAggregation: true,
              separator: ";",
            },
            {
              id: "1458",
              stringAggregation: true,
              separator: ";",
            },
            {
              id: "1459",
              stringAggregation: true,
              separator: ";",
            },
          ],
        },
        dataRequestCaching: true,
        dataSort: [
          {
            dim_id: "1382",
            sortField: "name",
            sortOrder: "asc",
          },
          {
            dim_id: "1387",
            sortField: "name",
            sortOrder: "asc",
          },
        ],
        dataFilter: {
          type: "AND",
          version: 1,
          operands: [
            {
              type: "EQ",
              version: 1,
              operands: [
                {
                  type: "DIM",
                  version: 1,
                  id: "1382",
                },
                {
                  type: "CONST",
                  version: 1,
                  values: [
                    params.period.indexOf("00", 4) === 4
                      ? params.period.slice(0, 4)
                      : params.period,
                  ],
                },
              ],
            },
            {
              type: "EQ",
              version: 1,
              operands: [
                {
                  type: "DIM",
                  version: 2,
                  id: "1387",
                  attributeCode: "Код ОКС",
                },
                {
                  type: "TEXT",
                  version: 2,
                  values: [params.id],
                },
              ],
            },
          ],
        },
      },
    },
  ]);
};

export const getObjectVideos = (params: IEventParams) => {
  return olapRequestData3([
    {
      requestId: "e629b294-3951-45b2-b762-6e0b2f455dbf",
      type: "data2",
      params: {
        modelUuid: "6f061c05-5d00-42e9-a92c-d1b59b2bb718",
        dashboardUuid: "",
        includeGaps: false,
        dimensions: [
          {
            id: "1382",
            includeItems: true,
            includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
          },
          {
            id: "1387",
            includeItems: true,
            includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
          },
        ],
        indicators: {
          id: "1402",
          items: [
            {
              id: "1456",
              aggregationFunction: "sum",
            },
            {
              id: "1467",
              aggregationFunction: "sum",
            },
          ],
        },
        dataRequestCaching: true,
        dataSort: [
          {
            dim_id: "1382",
            sortField: "name",
            sortOrder: "asc",
          },
          {
            dim_id: "1387",
            sortField: "name",
            sortOrder: "asc",
          },
        ],
        dataFilter: {
          type: "AND",
          version: 1,
          operands: [
            {
              type: "EQ",
              version: 1,
              operands: [
                {
                  type: "DIM",
                  version: 2,
                  id: "1387",
                  attributeCode: "Код ОКС",
                },
                {
                  type: "TEXT",
                  version: 2,
                  values: [params.id],
                },
              ],
            },
            {
              type: "EQ",
              version: 1,
              operands: [
                {
                  type: "DIM",
                  version: 1,
                  id: "1382",
                },
                {
                  type: "CONST",
                  version: 1,
                  values: [
                    params.period.indexOf("00", 4) === 4
                      ? params.period.slice(0, 4)
                      : params.period,
                  ],
                },
              ],
            },
          ],
        },
      },
    },
  ]);
};

export const getTopBest = () => {
  return olapRequestData3([
    {
      requestId: "175baed8-7166-4997-9cfc-9f60b4b1cade",
      type: "data2",
      params: {
        modelUuid: "c116bb29-cc0d-4f3a-a050-392dd7f41db8",
        dashboardUuid: "",
        includeGaps: false,
        dimensions: [
          {
            id: "1562",
            includeItems: true,
            includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
          },
          {
            id: "1565",
            includeItems: true,
            includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
          },
        ],
        indicators: {
          id: "1571",
          items: getGpIndicators(1547),
          limit: {
            id: "1547",
            order: "desc",
            type: "TOP",
            value: 5,
          },
        },
        dataRequestCaching: true,
        dataSort: [
          {
            dim_id: "1562",
            sortField: "name",
            sortOrder: "asc",
          },
          {
            dim_id: "1565",
            sortField: "name",
            sortOrder: "asc",
          },
        ],
        dataFilter: {
          type: "AND",
          version: 1,
          operands: [
            {
              type: "STARTER",
              version: 1,
              dim_id: "1565",
            },
            {
              type: "EQ",
              version: 1,
              operands: [
                {
                  type: "DIM",
                  version: 1,
                  id: "1562",
                },
                {
                  type: "CONST",
                  version: 1,
                  values: ["2022"],
                },
              ],
            },
          ],
        },
      },
    },
  ]);
};

export const getTopWorst = () => {
  return olapRequestData3([
    {
      requestId: "f249963f-43f9-4d47-b6e1-5d676872e22a",
      type: "data2",
      params: {
        modelUuid: "c116bb29-cc0d-4f3a-a050-392dd7f41db8",
        dashboardUuid: "",
        includeGaps: false,
        dimensions: [
          {
            id: "1562",
            includeItems: true,
            includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
          },
          {
            id: "1565",
            includeItems: true,
            includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
          },
        ],
        indicators: {
          id: "1571",
          items: getGpIndicators(1547),
          limit: {
            id: "1547",
            order: "asc",
            type: "TOP",
            value: 5,
          },
        },
        dataRequestCaching: true,
        dataSort: [
          {
            dim_id: "1562",
            sortField: "name",
            sortOrder: "asc",
          },
          {
            dim_id: "1565",
            sortField: "name",
            sortOrder: "asc",
          },
        ],
        dataFilter: {
          type: "AND",
          version: 1,
          operands: [
            {
              type: "STARTER",
              version: 1,
              dim_id: "1565",
            },
            {
              type: "EQ",
              version: 1,
              operands: [
                {
                  type: "DIM",
                  version: 1,
                  id: "1562",
                },
                {
                  type: "CONST",
                  version: 1,
                  values: ["2022"],
                },
              ],
            },
          ],
        },
      },
    },
  ]);
};

export const getGoalConnect = (id: string | number) => {
  return olapRequestData3([
    {
      requestId: "cd9f0aca-4c83-415d-863a-c14bccc1de94",
      type: "data2",
      params: {
        modelUuid: "2d1cc9ce-87da-40b3-80b6-37679e0357fd",
        dimensions: [],
        indicators: {},
        section_name: "2.0.3 Связь показателей ГП и НЦ",
        dataRequestCaching: false,
        filters: {
          gp_code: id,
        },
      },
    },
  ]);
};
// export const getEvents = (id: string | number) => {
//   return olapRequestData2({
//     modelUuid: MODEL_UUID,
//     dimensions: [],
//     indicators: {},
//     section_name: SECTION_NAMES.EVENTS,
//     filters: {
//       period_code: "202200",
//       gp_code: id,
//       dp_code: "dp241",
//     },
//   });
// };

// export const getEvent = (id: string | number) => {
//   return olapRequestData2({
//     modelUuid: EVENT_UUID,
//     dashboardUuid: "",
//     includeGaps: false,
//     dimensions: [
//       {
//         id: "141",
//         includeItems: true,
//         includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
//       },
//     ],
//     indicators: {
//       id: "95",
//       items: [
//         {
//           id: "0",
//           aggregationFunction: "sum",
//         },
//         {
//           id: "1",
//           aggregationFunction: "sum",
//         },
//         {
//           id: "5",
//           aggregationFunction: "sum",
//         },
//         {
//           id: "6",
//           aggregationFunction: "sum",
//         },
//         {
//           id: "14",
//           aggregationFunction: "sum",
//         },
//         {
//           id: "15",
//           aggregationFunction: "sum",
//         },
//         {
//           id: "16",
//           aggregationFunction: "sum",
//         },
//         {
//           id: "17",
//           aggregationFunction: "sum",
//         },
//         {
//           id: "18",
//           aggregationFunction: "sum",
//         },
//         {
//           id: "2",
//           aggregationFunction: "sum",
//         },
//         {
//           id: "20",
//           aggregationFunction: "sum",
//         },
//         {
//           id: "21",
//           aggregationFunction: "sum",
//         },
//         {
//           id: "25",
//           aggregationFunction: "sum",
//         },
//         {
//           id: "26",
//           aggregationFunction: "sum",
//         },
//         {
//           id: "27",
//           aggregationFunction: "sum",
//         },
//         {
//           id: "29",
//           aggregationFunction: "sum",
//         },
//         {
//           id: "30",
//           aggregationFunction: "sum",
//         },
//         {
//           id: "34",
//           aggregationFunction: "sum",
//         },
//         {
//           id: "35",
//           aggregationFunction: "sum",
//         },
//         {
//           id: "36",
//           aggregationFunction: "sum",
//         },
//         {
//           id: "37",
//           aggregationFunction: "sum",
//         },
//         {
//           id: "38",
//           aggregationFunction: "sum",
//         },
//         {
//           id: "39",
//           aggregationFunction: "sum",
//         },
//         {
//           id: "40",
//           aggregationFunction: "sum",
//         },
//         {
//           id: "41",
//           aggregationFunction: "sum",
//         },
//         {
//           id: "42",
//           aggregationFunction: "sum",
//         },
//         {
//           id: "43",
//           aggregationFunction: "sum",
//         },
//         {
//           id: "44",
//           aggregationFunction: "sum",
//         },
//         {
//           id: "45",
//           aggregationFunction: "sum",
//         },
//         {
//           id: "46",
//           aggregationFunction: "sum",
//         },
//         {
//           id: "47",
//           aggregationFunction: "sum",
//         },
//         {
//           id: "48",
//           aggregationFunction: "sum",
//         },
//         {
//           id: "49",
//           aggregationFunction: "sum",
//         },
//         {
//           id: "50",
//           aggregationFunction: "sum",
//         },
//         {
//           id: "51",
//           aggregationFunction: "sum",
//         },
//         {
//           id: "52",
//           aggregationFunction: "sum",
//         },
//         {
//           id: "53",
//           aggregationFunction: "sum",
//         },
//         {
//           id: "54",
//           aggregationFunction: "sum",
//         },
//         {
//           id: "55",
//           aggregationFunction: "sum",
//         },
//         {
//           id: "56",
//           aggregationFunction: "sum",
//         },
//         {
//           id: "90",
//           aggregationFunction: "sum",
//         },
//         {
//           id: "91",
//           aggregationFunction: "sum",
//         },
//         {
//           id: "107",
//           aggregationFunction: "sum",
//         },
//       ],
//     },
//     dataRequestCaching: false,
//     dataSort: [
//       {
//         dim_id: "141",
//         sortField: "name",
//         sortOrder: "asc",
//       },
//     ],
//     dataFilter: {
//       type: "AND",
//       version: 1,
//       operands: [
//         {
//           type: "AND",
//           version: 1,
//           operands: [
//             {
//               type: "EQ",
//               version: 1,
//               operands: [
//                 {
//                   type: "DIM",
//                   version: 2,
//                   id: "25",
//                   attributeCode: "_CODE",
//                 },
//                 {
//                   type: "TEXT",
//                   version: 2,
//                   values: ["__DashYM__"],
//                 },
//               ],
//             },
//             {
//               type: "EQ",
//               version: 1,
//               operands: [
//                 {
//                   type: "DIM",
//                   version: 2,
//                   id: "141",
//                   attributeCode: "R_ID",
//                 },
//                 {
//                   type: "TEXT",
//                   version: 2,
//                   values: ["__ResultID__"],
//                 },
//               ],
//             },
//           ],
//         },
//       ],
//     },
//   });
// };
//
// export const getCashExecution = (id: string | number) => {
//   return olapRequestData2({
//     modelUuid: "783d53d9-66a6-4960-b80f-6b06a4401130",
//     dashboardUuid: "",
//     includeGaps: false,
//     dimensions: [
//       {
//         id: "201",
//         includeItems: true,
//         includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
//       },
//     ],
//     indicators: {
//       id: "203",
//       items: [
//         {
//           id: "0",
//           aggregationFunction: "sum",
//         },
//         {
//           id: "1",
//           aggregationFunction: "sum",
//         },
//         {
//           id: "2",
//           aggregationFunction: "sum",
//         },
//       ],
//     },
//     dataRequestCaching: true,
//     dataSort: [
//       {
//         dim_id: "201",
//         sortField: "name",
//         sortOrder: "asc",
//       },
//     ],
//     dataFilter: {
//       type: "AND",
//       version: 1,
//       operands: [
//         {
//           type: "EQ",
//           version: 1,
//           operands: [
//             {
//               type: "DIM",
//               version: 2,
//               id: "25",
//               attributeCode: "_CODE",
//             },
//             {
//               type: "TEXT",
//               version: 2,
//               values: ["__DashYM__"],
//             },
//           ],
//         },
//         {
//           type: "EQ",
//           version: 1,
//           operands: [
//             {
//               type: "DIM",
//               version: 2,
//               id: "201",
//               attributeCode: "TYPE",
//             },
//             {
//               type: "TEXT",
//               version: 2,
//               values: ["1"],
//             },
//           ],
//         },
//         {
//           type: "EQ",
//           version: 1,
//           operands: [
//             {
//               type: "DIM",
//               version: 2,
//               id: "141",
//               attributeCode: "R_ID",
//             },
//             {
//               type: "TEXT",
//               version: 2,
//               values: ["__ResultID__"],
//             },
//           ],
//         },
//       ],
//     },
//   });
// };
//
// export const getControlPoints = (id: string | number) => {
//   return olapRequestData2({
//     modelUuid: "43ec6424-673b-4f09-9e8b-3538bb426ae8",
//     dashboardUuid: "",
//     includeGaps: false,
//     dimensions: [
//       {
//         id: "147",
//         includeItems: true,
//         includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
//       },
//     ],
//     indicators: {
//       id: "149",
//       items: [
//         {
//           id: "1",
//           stringAggregation: true,
//           separator: ";",
//         },
//         {
//           id: "2",
//           stringAggregation: true,
//           separator: ";",
//         },
//       ],
//     },
//     dataRequestCaching: true,
//     dataSort: [
//       {
//         dim_id: "147",
//         sortField: "name",
//         sortOrder: "asc",
//       },
//     ],
//     dataFilter: {
//       type: "AND",
//       version: 1,
//       operands: [
//         {
//           type: "EQ",
//           version: 1,
//           operands: [
//             {
//               type: "DIM",
//               version: 2,
//               id: "25",
//               attributeCode: "_CODE",
//             },
//             {
//               type: "TEXT",
//               version: 2,
//               values: ["__DashYM__"],
//             },
//           ],
//         },
//         {
//           type: "AND",
//           version: 1,
//           operands: [
//             {
//               type: "EQ",
//               version: 1,
//               operands: [
//                 {
//                   type: "DIM",
//                   version: 2,
//                   id: "147",
//                   attributeCode: "R_ID",
//                 },
//                 {
//                   type: "TEXT",
//                   version: 2,
//                   values: ["__ResultID__"],
//                 },
//               ],
//             },
//             {
//               type: "EQ",
//               version: 1,
//               operands: [
//                 {
//                   type: "DIM",
//                   version: 2,
//                   id: "147",
//                   attributeCode: "Тип",
//                 },
//                 {
//                   type: "TEXT",
//                   version: 2,
//                   values: ["КТ"],
//                 },
//               ],
//             },
//           ],
//         },
//       ],
//     },
//   });
// };
//
// export const getSubjectImplement = (id: string | number) => {
//   return olapRequestData2({
//     modelUuid: "57b45292-7e49-4af1-8814-da78a9e83bb0",
//     dashboardUuid: "",
//     includeGaps: false,
//     dimensions: [
//       {
//         id: "29",
//         includeItems: true,
//         includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
//       },
//     ],
//     indicators: {
//       id: "155",
//       items: [
//         {
//           id: "0",
//           aggregationFunction: "sum",
//         },
//         {
//           id: "1",
//           aggregationFunction: "sum",
//         },
//         {
//           id: "2",
//           stringAggregation: true,
//           separator: ";",
//         },
//         {
//           id: "3",
//           stringAggregation: true,
//           separator: ";",
//         },
//         {
//           id: "4",
//           stringAggregation: true,
//           separator: ";",
//         },
//         {
//           id: "7",
//           aggregationFunction: "sum",
//         },
//         {
//           id: "8",
//           aggregationFunction: "sum",
//         },
//         {
//           id: "9",
//           aggregationFunction: "sum",
//         },
//         {
//           id: "10",
//           aggregationFunction: "sum",
//         },
//       ],
//     },
//     dataRequestCaching: true,
//     dataSort: [
//       {
//         dim_id: "29",
//         sortField: "name",
//         sortOrder: "asc",
//       },
//     ],
//     dataFilter: {
//       type: "AND",
//       version: 1,
//       operands: [
//         {
//           type: "EQ",
//           version: 1,
//           operands: [
//             {
//               type: "DIM",
//               version: 2,
//               id: "25",
//               attributeCode: "_CODE",
//             },
//             {
//               type: "TEXT",
//               version: 2,
//               values: ["__DashYM__"],
//             },
//           ],
//         },
//         {
//           type: "PARENT",
//           version: 1,
//           dim_id: "29",
//           values: ["2"],
//         },
//         {
//           type: "EQ",
//           version: 1,
//           operands: [
//             {
//               type: "DIM",
//               version: 2,
//               id: "141",
//               attributeCode: "R_ID",
//             },
//             {
//               type: "TEXT",
//               version: 2,
//               values: ["__ResultID__"],
//             },
//           ],
//         },
//       ],
//     },
//   });
// };
//
// export const getObjectsEvents = (id: string | number) => {
//   return olapRequestData2({
//     modelUuid: "ac94193f-a625-4cf9-ac0c-471b999fd095",
//     dashboardUuid: "",
//     includeGaps: true,
//     dimensions: [
//       {
//         id: "161",
//         includeItems: true,
//         includeAttributesByCodes: ["NAME", "SHORT_NAME", "SORT_ORDER"],
//       },
//     ],
//     indicators: {
//       id: "163",
//       items: [
//         {
//           id: "6",
//           aggregationFunction: "sum",
//         },
//         {
//           id: "7",
//           aggregationFunction: "sum",
//         },
//         {
//           id: "8",
//           stringAggregation: true,
//           separator: ";",
//         },
//         {
//           id: "9",
//           stringAggregation: true,
//           separator: ";",
//         },
//         {
//           id: "10",
//           stringAggregation: true,
//           separator: ";",
//         },
//         {
//           id: "11",
//           stringAggregation: true,
//           separator: ";",
//         },
//         {
//           id: "12",
//           stringAggregation: true,
//           separator: ";",
//         },
//         {
//           id: "13",
//           stringAggregation: true,
//           separator: ";",
//         },
//         {
//           id: "14",
//           stringAggregation: true,
//           separator: ";",
//         },
//         {
//           id: "15",
//           stringAggregation: true,
//           separator: ";",
//         },
//         {
//           id: "16",
//           stringAggregation: true,
//           separator: ";",
//         },
//         {
//           id: "17",
//           stringAggregation: true,
//           separator: ";",
//         },
//         {
//           id: "18",
//           stringAggregation: true,
//           separator: ";",
//         },
//         {
//           id: "19",
//           stringAggregation: true,
//           separator: ";",
//         },
//         {
//           id: "20",
//           stringAggregation: true,
//           separator: ";",
//         },
//       ],
//     },
//     dataRequestCaching: true,
//     dataSort: [
//       {
//         type: "attribute",
//         dim_id: "281",
//         order: "desc",
//         attribute: "SORT_ORDER",
//       },
//     ],
//     pagination: {
//       page: 1,
//       pageSize: 50,
//       sideDimensions: ["161"],
//     },
//     dataFilter: {
//       type: "EQ",
//       version: 1,
//       operands: [
//         {
//           type: "DIM",
//           version: 2,
//           id: "161",
//           attributeCode: "R_ID",
//         },
//         {
//           type: "TEXT",
//           version: 2,
//           values: ["__ResultID__"],
//         },
//       ],
//     },
//   });
// };
