import { Box, SxProps } from "@mui/material";
import React, { useMemo } from "react";
import { ProgressDoughnutPale } from "SRC/components/Charts";
import { ProgressBar } from "SRC/components/ProgressBar/ProgressBar";
import theme from "SRC/theme";

import { css } from "./Effectiveness.styled";

export const data = {
  mainEffect: {
    total: 100,
    value: 95,
  },
  effectItems: [
    {
      id: 1,
      title: "Сохранение населения, здоровье и благополучие людей",
      number: 5,
      progress: 86,
    },
    {
      id: 2,
      title: "Возможности для самореализации и развития талантов",
      number: 4,
      progress: 25,
    },
    {
      id: 3,
      title: "Комфортная и безопасная среда для жизни",
      number: 5,
      progress: 90,
    },
    {
      id: 4,
      title: "Эффективность выполнения госпрограмм",
      number: 5,
      progress: 90,
    },
    {
      id: 5,
      title: "Развитие науки, промышленности и технологий",
      number: 8,
      progress: 15,
    },
    {
      id: 6,
      title: "Цифровая трансформация",
      number: 5,
      progress: 5,
    },
    {
      id: 7,
      title: "Сбалансированное региональное развитие",
      number: 5,
      progress: 30,
    },
    {
      id: 8,
      title: "Эффективность выполнения госпрограмм",
      number: 5,
      progress: 50,
    },
  ],
};

export const Effectiveness = () => {
  const effectItems = useMemo(
    (): JSX.Element[] =>
      data.effectItems.map((item) => (
        <Box sx={css.effectItem as SxProps}>
          <Box sx={css.itemTop}>
            <Box sx={css.title}>{item.title}</Box>
            <Box sx={css.num}>{item.number}</Box>
          </Box>
          <Box sx={css.itemBottom}>
            <Box sx={css.barContainer}>
              <Box sx={css.barTitle}>Уровень достижения</Box>
              <Box sx={css.barUnit}>{item.progress}</Box>
            </Box>
            <ProgressBar
              value={item.progress}
              progressColor={theme.palette.pale.green}
            />
          </Box>
        </Box>
      )),
    []
  );

  return (
    <Box sx={css.container}>
      <Box sx={css.generalEffect}>
        <Box sx={css.title}>Эффективность выполнения программ</Box>
        <Box sx={css.chart}>
          <ProgressDoughnutPale
            total={data?.mainEffect?.total}
            value={data?.mainEffect?.value}
          />
        </Box>
      </Box>
      <Box sx={css.effectsContainer}>{effectItems}</Box>
    </Box>
  );
};
