import { actions as goals } from "./slices/goals";
import { actions as ministries } from "./slices/ministries";
import { actions as premiers } from "./slices/premiers";
import { actions2 as premiersFull } from "./slices/premiers";
import { actions as programs } from "./slices/programs";
import { actions2 as programsFull } from "./slices/programs";
import { actions as stats } from "./slices/stats";

export interface IMainActions {
  ministries: typeof ministries;
  programs: typeof programs;
  programsFull: typeof programsFull;
  goals: typeof goals;
  premiers: typeof premiers;
  premiersFull: typeof premiersFull;
  stats: typeof stats;
}

const actions: IMainActions = {
  ministries,
  programs,
  programsFull,
  goals,
  premiers,
  premiersFull,
  stats,
};

export default actions;
