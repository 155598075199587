import { useTheme } from "@mui/material";
import React, { SyntheticEvent, useMemo, useState } from "react";
import { Column, useGlobalFilter, useSortBy, useTable } from "react-table";
import { CustomTabs } from "SRC/components/CustomTabs/CustomTabs";
import GraphSwitcher from "SRC/components/GraphSwitcher/GraphSwitcher";
import Counter from "SRC/components/ProgramsCounter/ProgramsCounter";
import {
  getDataSumByCodeGP,
  getDataSumByCodeMin,
  getDataSumByCodeVP,
} from "SRC/constants/dataCodes";
import {
  getInfoRowsGovProgram,
  getInfoRowsMinistries,
  getInfoRowsVicePremiers,
} from "SRC/helpers/getInfoRows";
import useTableExportParams from "SRC/hooks/useTableExportParams";
import { GovProgramsFilters } from "SRC/pages/Main/common/ComplexGrid/filterComponents/GovProgramsFilters";
// import { MinistriesFilters } from "SRC/pages/Main/common/ComplexGrid/filterComponents/MinistriesFilters";
import { VicePremiersFilters } from "SRC/pages/Main/common/ComplexGrid/filterComponents/VicePremiersFilters";
import { GovProgramsTab } from "SRC/pages/Main/common/ComplexGrid/GovProgramsTab/GovProgramsTab";
import { getGovProgramsColumns } from "SRC/pages/Main/common/ComplexGrid/GovProgramsTab/tableData";
import {
  IGovProgramDataItem,
  IGovProgramDataItemWithoutIcon,
  IMinistriesDataItem,
  IVicePremiersDataItem,
} from "SRC/pages/Main/common/ComplexGrid/util/interfaces";
import { getVicePremiersColumns } from "SRC/pages/Main/common/ComplexGrid/VicePremiersTab/tableData";
import { VicePremiersTab } from "SRC/pages/Main/common/ComplexGrid/VicePremiersTab/VicePremiersTab";
import { MinistriesTab } from "SRC/pages/MainNew/common/ComplexGrid/MinistriesTab/MinistriesTab";
import { getMinistriesColumns } from "SRC/pages/MainNew/common/ComplexGrid/MinistriesTab/tableData";
import { css } from "SRC/pages/Summary/common/SummaryComplexGrid/SummaryComplexGrid.styled";
import { IFilters, useFilters } from "SRC/redux/slices/main/hooks/useFilters";
import {
  TMinistry,
  useMinistries,
} from "SRC/redux/slices/main/hooks/useMInistries";
import {
  IPremiersFull,
  TPremier,
  usePremiersFull,
} from "SRC/redux/slices/main/hooks/usePremiers";
import { TProgram, usePrograms } from "SRC/redux/slices/main/hooks/usePrograms";
import { IDataItemEl } from "SRC/redux/slices/main/slices/ministries";
import { IProgram } from "SRC/redux/slices/main/slices/programs";
import themeConfig from "SRC/theme";
import { Range } from "xlsx";

const tabs = [
  {
    value: "govPrograms",
    label: "Госпрограммы",
  },
  {
    value: "vicePremiers",
    label: "Вице-премьеры",
  },
  {
    value: "ministries",
    label: "Министерства",
    disabled: false,
  },
  {
    value: "regions",
    label: "Регионы",
    disabled: true,
  },
];

interface ITabExportData {
  downloadData:
    | IGovProgramDataItemWithoutIcon[]
    | IVicePremiersDataItem[]
    | IMinistriesDataItem[];
  fileName: string;
  headings: Record<string, string | object>[];
  mergeCells: Range[] | undefined;
  wscols: Record<string, number>[];
  infoRows: Record<string, string>[][];
}

type TExportData = {
  [key: string]: ITabExportData;
};

export const ComplexGrid = () => {
  const theme = useTheme<typeof themeConfig>();

  const [isGovProgramsModalOpen, setGovProgramsModalOpen] = useState(false);
  const [isVicePremiersModalOpen, setVicePremiersModalOpen] = useState(false);
  const [isMinistriesModalOpen, setMinistriesModalOpen] = useState(false);
  const [govProgramsTableFilter, setGovProgramsTableFilter] = useState<
    "Отраслевая" | "Секретная" | "Комплексная" | ""
  >("");

  const {
    program,
    setSelectedProgram,
    filteredPrograms,
    vp,
    setSelectedVP,
    filteredVPs,
    ministry,
    setSelectedMinistry,
    filteredMinistries,
    setSelectedComplexGridTab,
    complexGridTab,
    calculationMethod,
  }: IFilters = useFilters();
  const { items: ministries } = useMinistries();
  const { items: programs } = usePrograms();

  const { elems: premiers }: IPremiersFull = usePremiersFull();
  const handleTabChange = (event: SyntheticEvent, newValue: string) => {
    setSelectedComplexGridTab(newValue);
  };

  const govProgramsTableData: IProgram[] = useMemo(() => {
    if (govProgramsTableFilter) {
      return programs?.filter((item: IProgram) =>
        Number(item[govProgramsTableFilter])
      );
    }
    return programs;
  }, [govProgramsTableFilter, programs]);

  const govProgramsData = useMemo<IGovProgramDataItem[]>(
    () =>
      govProgramsTableData
        .filter((program) => filteredPrograms.includes(program.gp_code))
        .map((item: TProgram) => ({
          id: item.gp_code,
          icon: item.gp_icon_url,
          govProgram: item.gp_short_name,
          indicators: getDataSumByCodeGP(item, 1578) || 0,
          plan: getDataSumByCodeGP(item, 1584) || 0,
          fact: getDataSumByCodeGP(item, 1679) || 0,
          atRisk: getDataSumByCodeGP(item, 1680) || 0,
          achievementLevel:
            calculationMethod === "effect"
              ? getDataSumByCodeGP(item, 1753) || 0
              : getDataSumByCodeGP(item, 1796) || 0,
          secretProgram: Number(item["Секретная"]),
        }))
        .sort((a: IGovProgramDataItem, b: IGovProgramDataItem) => {
          return Number(a.id) - Number(b.id);
        }),
    [govProgramsTableData, filteredPrograms, calculationMethod]
  );

  //todo: убрать any
  const govProgramsColumns: any = useMemo(
    () => getGovProgramsColumns(theme),
    [theme]
  );

  const vicePremiersTableData = useMemo<IVicePremiersDataItem[]>(
    () =>
      premiers
        .filter((premier) => filteredVPs.includes(premier.VP_CODE))
        .map((item: TPremier) => ({
          vicePremierName: item.VP_SHORT_NAME,
          vicePremierPhoto: item.VP_PHOTO_URL,
          numOfPrograms: getDataSumByCodeVP(item, 1742) || 0,
          plan: getDataSumByCodeVP(item, 1747) || 0,
          fact: getDataSumByCodeVP(item, 1748) || 0,
          atRisk: getDataSumByCodeVP(item, 1749) || 0,
          achievementLevel:
            calculationMethod === "effect"
              ? getDataSumByCodeVP(item, 1743) || 0
              : getDataSumByCodeVP(item, 1797) || 0,
        })),
    [premiers, filteredVPs, calculationMethod]
  );

  const vicePremiersTableColumns: Column[] = useMemo(
    () => getVicePremiersColumns(theme),
    [theme]
  );

  const ministriesTableData = useMemo<IMinistriesDataItem[]>(
    () =>
      ministries
        .filter((ministry) => filteredMinistries.includes(ministry.code))
        .map((item: TMinistry) => {
          return {
            ministryName: item.NAME,
            numOfPrograms: getDataSumByCodeMin(item, 1782) || 0,
            plan: getDataSumByCodeMin(item, 1785) || 0,
            fact: getDataSumByCodeMin(item, 1786) || 0,
            atRisk: getDataSumByCodeMin(item, 1787) || 0,
            achievementLevel: getDataSumByCodeMin(item, 1788) || 0,
          };
        }),
    [ministries, filteredMinistries]
  );

  const ministriesTableColumns: Column[] = useMemo(
    () => getMinistriesColumns(theme),
    [theme]
  );

  const {
    getTableProps: getGovProgramsTableProps,
    getTableBodyProps: getGovProgramsTableBodyProps,
    headerGroups: govProgramsTableHeaderGroups,
    footerGroups: govProgramsTableFooterGroups,
    rows: govProgramsTableRows,
    prepareRow: govProgramsTablePrepareRow,
    setSortBy: setGovProgramsTableSortBy,
    state: govProgramsTableState,
    setGlobalFilter: setGovProgramsTableGlobalFilter,
  } = useTable(
    {
      columns: govProgramsColumns,
      data: govProgramsData,
    },
    useGlobalFilter,
    useSortBy
  );

  const { globalFilter: govProgramsTableGlobalFilter } = govProgramsTableState;

  const {
    getTableProps: getVicePremiersTableProps,
    getTableBodyProps: getVicePremiersTableBodyProps,
    headerGroups: vicePremiersTableHeaderGroups,
    rows: vicePremiersTableRows,
    prepareRow: vicePremiersTablePrepareRow,
    setSortBy: setVicePremiersTableSortBy,
    state: vicePremiersTableState,
    setGlobalFilter: setVicePremiersTableGlobalFilter,
  } = useTable(
    {
      columns: vicePremiersTableColumns,
      data: vicePremiersTableData,
    },
    useGlobalFilter,
    useSortBy
  );

  const {
    getTableProps: getMinistriesTableProps,
    getTableBodyProps: getMinistriesTableBodyProps,
    headerGroups: ministriesTableHeaderGroups,
    rows: ministriesTableRows,
    prepareRow: ministriesTablePrepareRow,
    setSortBy: setMinistriesTableSortBy,
    state: ministriesTableState,
    setGlobalFilter: setMinistriesTableGlobalFilter,
  } = useTable(
    {
      columns: ministriesTableColumns,
      data: ministriesTableData,
    },
    useGlobalFilter,
    useSortBy
  );

  const { globalFilter: vicePremiersTableGlobalFilter } =
    vicePremiersTableState;

  const { globalFilter: ministriesTableGlobalFilter } = ministriesTableState;

  const govProgramsDataExport = govProgramsData.map(
    ({ icon, secretProgram, ...item }) => item
  );

  const infoRowsGovProgram = useMemo(
    () => getInfoRowsGovProgram(program, programs, govProgramsTableFilter),
    [program, govProgramsTableFilter, programs]
  );

  const infoRowsVicePremiers = useMemo(
    () => getInfoRowsVicePremiers(govProgramsTableFilter),
    [govProgramsTableFilter]
  );

  const infoRowsMinistries = useMemo(
    () => getInfoRowsMinistries(govProgramsTableFilter),
    [govProgramsTableFilter]
  );

  const {
    headings: govProgramsHeadings,
    mergeCells: govProgramsMergeCells,
    wscols: govProgramsWscols,
  } = useTableExportParams(govProgramsDataExport, "govPrograms");
  const {
    headings: vicePremiersHeadings,
    mergeCells: vicePremiersMergeCells,
    wscols: vicePremiersWscols,
  } = useTableExportParams(vicePremiersTableData, "vicePremiers");
  const {
    headings: ministriesHeadings,
    mergeCells: ministriesMergeCells,
    wscols: ministriesWscols,
  } = useTableExportParams(ministriesTableData, "ministries");

  const tabExportData: ITabExportData = useMemo(() => {
    const exportData: TExportData = {
      govPrograms: {
        downloadData: govProgramsDataExport,
        fileName: "Государственные программы",
        wscols: govProgramsWscols,
        headings: govProgramsHeadings,
        mergeCells: govProgramsMergeCells,
        infoRows: infoRowsGovProgram,
      },
      vicePremiers: {
        downloadData: vicePremiersTableData,
        fileName: "Вице-премьеры",
        wscols: vicePremiersWscols,
        headings: vicePremiersHeadings,
        mergeCells: vicePremiersMergeCells,
        infoRows: infoRowsVicePremiers,
      },
      ministries: {
        downloadData: ministriesTableData,
        fileName: "Министерства",
        wscols: ministriesWscols,
        headings: ministriesHeadings,
        mergeCells: ministriesMergeCells,
        infoRows: infoRowsMinistries,
      },
    };
    return exportData[complexGridTab];
  }, [
    complexGridTab,
    govProgramsData,
    ministriesTableData,
    vicePremiersTableData,
  ]);

  const { downloadData, fileName, wscols, headings, mergeCells, infoRows } =
    tabExportData;

  const components = [
    <GovProgramsTab
      setGlobalFilter={setGovProgramsTableGlobalFilter}
      globalFilter={govProgramsTableGlobalFilter}
      tableFilter={govProgramsTableFilter}
      setFilter={setGovProgramsTableFilter}
      data={govProgramsData}
      columns={govProgramsColumns}
      footerGroups={govProgramsTableFooterGroups}
      getTableBodyProps={getGovProgramsTableBodyProps}
      getTableProps={getGovProgramsTableProps}
      headerGroups={govProgramsTableHeaderGroups}
      prepareRow={govProgramsTablePrepareRow}
      program={program}
      rows={govProgramsTableRows}
      setSelectedProgram={setSelectedProgram}
      setSortBy={setGovProgramsTableSortBy}
      isTableModalOpen={isGovProgramsModalOpen}
      setTableModalOpen={setGovProgramsModalOpen}
      downloadData={govProgramsDataExport}
      infoRows={infoRowsGovProgram}
    />,
    <VicePremiersTab
      vicePremiersTableColumns={vicePremiersTableColumns}
      vicePremiersTableData={vicePremiersTableData}
      setSortBy={setVicePremiersTableSortBy}
      rows={vicePremiersTableRows}
      prepareRow={vicePremiersTablePrepareRow}
      headerGroups={vicePremiersTableHeaderGroups}
      getTableProps={getVicePremiersTableProps}
      getTableBodyProps={getVicePremiersTableBodyProps}
      globalFilter={vicePremiersTableGlobalFilter}
      setGlobalFilter={setVicePremiersTableGlobalFilter}
      setSelectedVP={setSelectedVP}
      vp={vp}
      isTableModalOpen={isVicePremiersModalOpen}
      setTableModalOpen={setVicePremiersModalOpen}
      infoRows={infoRowsVicePremiers}
    />,
    <MinistriesTab
      ministriesTableColumns={ministriesTableColumns}
      ministriesTableData={ministriesTableData}
      setSortBy={setMinistriesTableSortBy}
      rows={ministriesTableRows}
      prepareRow={ministriesTablePrepareRow}
      headerGroups={ministriesTableHeaderGroups}
      getTableProps={getMinistriesTableProps}
      getTableBodyProps={getMinistriesTableBodyProps}
      globalFilter={ministriesTableGlobalFilter}
      setGlobalFilter={setMinistriesTableGlobalFilter}
      setSelectedMinistry={setSelectedMinistry}
      ministries={ministry}
      isTableModalOpen={isMinistriesModalOpen}
      setTableModalOpen={setMinistriesModalOpen}
      infoRows={infoRowsMinistries}
    />,
  ];

  return (
    <CustomTabs
      value={complexGridTab}
      onChange={handleTabChange}
      tabs={tabs}
      components={components}
      withBgr
      contained
      additional={<GraphSwitcher />}
      rightNode={<Counter />}
      style={css.tabsWrapper(theme, false, true)}
      borderType={"none"}
      withTableButtons
      isTableModalOpen={
        complexGridTab === "govPrograms"
          ? isGovProgramsModalOpen
          : isVicePremiersModalOpen
      }
      setTableModalOpen={
        complexGridTab === "govPrograms"
          ? setGovProgramsModalOpen
          : setVicePremiersModalOpen
      }
      rightSideFilters={
        complexGridTab === "govPrograms" ? (
          <GovProgramsFilters
            globalFilter={govProgramsTableGlobalFilter}
            setGlobalFilter={setGovProgramsTableGlobalFilter}
            setFilter={setGovProgramsTableFilter}
            tableFilter={govProgramsTableFilter}
          />
        ) : (
          <VicePremiersFilters
            globalFilter={vicePremiersTableGlobalFilter}
            setGlobalFilter={setVicePremiersTableGlobalFilter}
          />
        )
      }
      downloadData={downloadData}
      fileName={fileName}
      wscols={wscols}
      headings={headings}
      mergeCells={mergeCells}
      infoRows={infoRows}
    />
  );
};
