import { combineReducers } from "@reduxjs/toolkit";
import cashExecutionSlice, {
  ICashExecutionState,
} from "SRC/redux/slices/gosprogram/slices/cashExecution";
import controlPointsSlice, {
  IControlPointsState,
} from "SRC/redux/slices/gosprogram/slices/controlPoints";
import goalConnect, {
  IGoalConnectState,
} from "SRC/redux/slices/gosprogram/slices/goalConnect";
import listOfObjectsSlice, {
  IListOfObjectsState,
} from "SRC/redux/slices/gosprogram/slices/listOfObjects";
import modalSideBarSlice, {
  IModalSideBarState,
} from "SRC/redux/slices/gosprogram/slices/modalSideBar";
import objectPhotosSlice, {
  IObjectPhotosState,
} from "SRC/redux/slices/gosprogram/slices/objectPhotos";
import objectVideosSlice, {
  IObjectVideosState,
} from "SRC/redux/slices/gosprogram/slices/objectVideos";
import programFiltersSlice, {
  IProgramFiltersState,
} from "SRC/redux/slices/gosprogram/slices/programFilters";
import programsTopBest, {
  IProgramsTopBestState,
} from "SRC/redux/slices/gosprogram/slices/programsTopBest";
import resultCharacteristicSlice, {
  IResultCharacteristicState,
} from "SRC/redux/slices/gosprogram/slices/resultCharacteristic";
import statisticsSlice, {
  IStatisticsState,
} from "SRC/redux/slices/gosprogram/slices/statistics";
import structureElementsSlice, {
  IStructureElementsState,
} from "SRC/redux/slices/gosprogram/slices/structureElements";
import subjectsSlice, {
  ISubjectsState,
} from "SRC/redux/slices/gosprogram/slices/subjects";
import headerPrograms, {
  IHeaderProgramsState,
} from "SRC/redux/slices/main/slices/headerPrograms";

import programEventsSlice, {
  IProgramEventsState,
} from "./slices/programEvents";
import programGoalsSlice, { IProgramGoalsState } from "./slices/programGoals";
import programsTopWorst, {
  IProgramsTopWorstState,
} from "./slices/programsTopWorst";
import tableTypeSlice, { ITableTypeState } from "./slices/tableType";

export interface IGosprogramReducer {
  programGoals: IProgramGoalsState;
  programEvents: IProgramEventsState;
  statistics: IStatisticsState;
  structureElements: IStructureElementsState;
  tableType: ITableTypeState;
  controlPoints: IControlPointsState;
  programFilters: IProgramFiltersState;
  cashExecution: ICashExecutionState;
  subjects: ISubjectsState;
  listOfObjects: IListOfObjectsState;
  resultCharacteristic: IResultCharacteristicState;
  modalSideBar: IModalSideBarState;
  objectPhotos: IObjectPhotosState;
  objectVideos: IObjectVideosState;
  programsTopBest: IProgramsTopBestState;
  programsTopWorst: IProgramsTopWorstState;
  goalConnects: IGoalConnectState;
  headerPrograms: IHeaderProgramsState;
}

const reducer = combineReducers<IGosprogramReducer>({
  programGoals: programGoalsSlice.reducer,
  programEvents: programEventsSlice.reducer,
  statistics: statisticsSlice.reducer,
  structureElements: structureElementsSlice.reducer,
  tableType: tableTypeSlice.reducer,
  controlPoints: controlPointsSlice.reducer,
  programFilters: programFiltersSlice.reducer,
  cashExecution: cashExecutionSlice.reducer,
  subjects: subjectsSlice.reducer,
  listOfObjects: listOfObjectsSlice.reducer,
  resultCharacteristic: resultCharacteristicSlice.reducer,
  modalSideBar: modalSideBarSlice.reducer,
  objectPhotos: objectPhotosSlice.reducer,
  objectVideos: objectVideosSlice.reducer,
  programsTopBest: programsTopBest.reducer,
  programsTopWorst: programsTopWorst.reducer,
  goalConnects: goalConnect.reducer,
  headerPrograms: headerPrograms.reducer,
});

export default reducer;
