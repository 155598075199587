import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getAllPremiersInfo,
  getPremiers,
} from "SRC/redux/slices/main/api/client";
import { IPeriod } from "SRC/redux/slices/main/slices/filters";

interface IDataItem {
  sum?: number;
  code: string;
  text?: string;
}

export interface IPremier {
  VP_NAME: string;
  VP_CODE: string;
  VP_PHOTO_URL: string;
  VP_SHORT_NAME: string;
  code: string;
  data: IDataItem[];
}

export interface IPremiersState {
  items: IPremier[];
  elems: IPremier[];
  fetching: boolean;
  fetched: boolean;
  error: string;
}

export const extraActions = {
  get: createAsyncThunk(
    "premiers/get",
    async (period: IPeriod) => await getPremiers(period)
  ),
};

export const extraActions2 = {
  get: createAsyncThunk(
    "premiers-full/get",
    async () => await getAllPremiersInfo()
  ),
};

const initialState: IPremiersState = {
  items: [],
  elems: [],
  fetching: false,
  fetched: false,
  error: "",
};

const isIPremier = (array: any): array is IPremier[] => !!array?.length;

export const slice = createSlice({
  name: "premiers",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(extraActions.get.pending, (state) => {
        state.items = [];
        state.fetching = true;
        state.fetched = false;
      })
      .addCase(extraActions.get.fulfilled, (state: IPremiersState, action) => {
        state.items = isIPremier(action.payload.data)
          ? action.payload.data
          : [];
        state.fetching = false;
        state.fetched = true;
      })
      .addCase(extraActions.get.rejected, (state, action) => {
        state.fetching = false;
        // state.error = action.error;
      });
  },
});

export const slice2 = createSlice({
  name: "premiersFull",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(extraActions2.get.pending, (state) => {
        state.items = [];
        state.fetching = true;
        state.fetched = false;
      })
      .addCase(extraActions2.get.fulfilled, (state: IPremiersState, action) => {
        state.elems = isIPremier(action.payload.data)
          ? action.payload.data
          : [];
        state.fetching = false;
        state.fetched = true;
      })
      .addCase(extraActions2.get.rejected, (state, action) => {
        state.fetching = false;
        // state.error = action.error;
      });
  },
});

export const actions = {
  ...slice.actions,
  ...extraActions,
};

export const actions2 = {
  ...slice2.actions,
  ...extraActions2,
};
