import { useCallback } from "react";
import { useGoalConnect } from "SRC/redux/slices/gosprogram/hooks/useGoalConnect";
import { useProgramEvents } from "SRC/redux/slices/gosprogram/hooks/useProgramEvents";
import { useStatistics } from "SRC/redux/slices/gosprogram/hooks/useStatistics";
import { IParams } from "SRC/redux/slices/gosprogram/slices/programEvents";

import { useProgramGoals } from "./useProgramGoals";

export interface IMainState {
  loading: boolean;
  loaded: boolean;
  init(params: IParams): void;
  hasError: any;
}

export const useGosprogram = (): IMainState => {
  const {
    fetching: loadingGoals,
    load: loadProgramGoals,
    fetched: loadedGoals,
    error: errorProgramGoals,
  } = useProgramGoals();
  const {
    fetching: loadingEvents,
    load: loadProgramEvents,
    fetched: loadedEvents,
    error: errorEvents,
  } = useProgramEvents();
  const {
    fetching: loadingStatistics,
    load: loadStatistics,
    fetched: loadedStatistics,
    error: errorStatistics,
  } = useStatistics();
  const {
    fetching: loadingGoalConnect,
    load: loadGoalConnect,
    fetched: loadedGoalConnect,
    error: errorGoalConnect,
  } = useGoalConnect();

  const loading =
    loadingGoals || loadingEvents || loadingStatistics || loadingGoalConnect;
  const loaded =
    loadedGoals && loadedEvents && loadedStatistics && loadedGoalConnect;
  const hasError =
    errorEvents || errorProgramGoals || errorStatistics || errorGoalConnect;
  const init = useCallback(
    (params) => {
      const { id } = params;
      if (!loading) {
        loadProgramGoals(id);
        loadProgramEvents(params);
        loadStatistics(id);
        loadGoalConnect(params);
      }
    },
    [loading, loadProgramGoals, loadProgramEvents, loadGoalConnect]
  );

  return {
    loading,
    loaded,
    init,
    hasError,
  };
};
